<template>
  <div>
    <div class="logo">
      <router-link to="/datas_data">
        <img src="@/assets/szlogo2.png" alt="" />
        <span>数据管理系统</span>
      </router-link>
    </div>
    <div class="header-nav">
      <div class="navList">
        <div style="float: left;">
        <router-link :to="dataUrl">数据查询</router-link>
        <!-- <a-divider type="vertical" />
        <router-link to="/datas_articles">原文查看</router-link> -->
        </div>
        <div class="username">
            <a :title="userInfo.realName">
                {{ userInfo.realName}}
            </a>
            <a-dropdown placement="bottomCenter">
                <span class="ant-dropdown-link fr" style="cursor:pointer" @click="e => e.preventDefault()">
                    <a-icon type="caret-down" />
                </span>
                <template #overlay>
                    <a-menu style="margin-top:6px">
                        <a-menu-item>
                            <router-link to="/user_info">基本信息</router-link>
                        </a-menu-item>
                        <a-menu-item>
                            <router-link to="/user_collections">我的收藏</router-link>
                        </a-menu-item>
                        <a-menu-item>
                            <router-link to="/user_series">我的序列</router-link>
                        </a-menu-item>
                        <a-menu-item>
                            <router-link to="/user_history">浏览历史</router-link>
                        </a-menu-item>
                        <a-menu-item>
                            <a href="javascript:;" @click="logout">退出</a>
                        </a-menu-item>
                    </a-menu>
                </template>
            </a-dropdown>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { delCookie, delSessionItem } from '@/until/tools'
export default {
  data() {
    return {};
  },
  computed: {
    userInfo() {
        return this.$store.getters.getUserInfo;
    },
    dataUrl() {
      let obj = {
        path: "/datas_data",
      };
      return obj;
    },
  },
  methods:{
      logout() {
          var that = this;
          this.$confirm({
              title: '提示',
              content: '您确定要退出吗？',
              okText: '确定',
              cancelText: '取消',
              centered:true,
              onOk() {
                  // setCookie('loginTargetUrl',that.$route.path,{time:1});
                  delCookie('bd_sid');
                  delCookie('canInput');
                  delSessionItem('dims');
                  that.$router.push('/login');
              }
          })
      },
  }
};
</script>

<style lang="scss">
.search {
  .ant-input {
    background: none;
    color: #fff;
  }
}
</style>
<style lang="scss" scoped>
/deep/.ant-dropdown-menu-item{
  padding: 0px 20px !important;
  line-height: 29px !important;
  border-bottom: 1px solid #e8e8e8;
  &:last-child{
    border:none;
  }
  >a{
    margin: 0;
    padding: 0
  }
}
@import "../../../scss/datas/header.scss";
</style>