<template>
	<a-layout style="height:100%;overflow:hidden">
		<a-layout-header style="height:74px;">
			<Header />
		</a-layout-header>
		<router-view />
	</a-layout>
</template>

<script>
import Header from './components/CloudHeader.vue';
export default {    
	components:{Header},
	data() {
		return {}
	},
	created() {

	}
}
</script>